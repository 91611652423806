.contact-view {
  iframe {
    border: 0;
    width: 100%;
    height: 300px;
    @media (max-width: 767px) {
      height: 250px;
    }
  }
}
