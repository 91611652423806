.home-view {
  .center-image {
    background-image: url(../images/vegan-cheese.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    margin-bottom: 14px;
    @media (max-width: 767px) {
      height: 150px;
    }
    .ui.container {
      height: 100%;
      position: relative;
      // cancel small-screen container margins
      margin: auto !important;
      .ui.menu {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0;
        .item.active {
          // hide home icon on home page
          display: none;
        }
      }
    }
  }
}
