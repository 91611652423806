.ui.container.main-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1em;

  .logo {
    width: 250px;

    @media only screen and (max-width: 600px) {
      width: 180px;
    }

    &:hover {
      filter: saturate(2);
      transition: all 0.2s ease;
    }
  }
}